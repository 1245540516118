import "./App.css";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import rough from "roughjs/bundled/rough.esm";

const ACTION_TYPES = {
  NONE: "none",
  DRAWING: "drawing",
  MOVING: "moving",
  RESIZING: "resizing",
  PANNING: "panning",
};

const POSITIONS = {
  TOP_LEFT: "tl",
  TOP_RIGHT: "tr",
  BOTTOM_LEFT: "bl",
  BOTTOM_RIGHT: "br",
  START: "start",
  END: "end",
  INSIDE: "inside",
};

const TOOLS = {
  SELECT: "select",
  DELETE: "delete",
  LINE: "line",
  RECT: "rect",
  PAN: "pan",
  IMAGE: "image",
  TOKEN: "token",
  PLACE_NPC_TOKEN: "placeNpcToken",
};

const generator = rough.generator();

const createElement = (playerId, id, x1, y1, x2, y2, type, offset, originalPosAndSize = null, color = "black") => {
  let element;
  const { x: offX, y: offY } = offset;
  switch (type) {
    case TOOLS.LINE:
      element = generator.line(x1 + offX, y1 + offY, x2 + offX, y2 + offY, {
        stroke: color,
        roughness: 2,
        strokeWidth: 2,
      });
      break;
    case TOOLS.RECT:
      element = generator.rectangle(x1 + offX, y1 + offY, x2 - x1, y2 - y1, {
        stroke: color,
        roughness: 2,
        strokeWidth: 2,
      });
      break;
    default:
      break;
  }
  return {
    playerId,
    id,
    x1,
    y1,
    x2,
    y2,
    element: element,
    type,
    originalPositionAndSize: originalPosAndSize ? originalPosAndSize : { x1, y1, x2, y2 },
    color,
  };
};

const nearPoint = (x, y, x1, y1, position, offset) => {
  const { x: offX, y: offY } = offset;
  return Math.abs(x + offX - (x1 + offX)) < 5 && Math.abs(y + offY - (y1 + offY)) < 5 ? position : null;
};

const positionWithinElement = (x, y, element, offset) => {
  if (element) {
    const { x1, y1, x2, y2, type } = element;
    switch (type) {
      case TOOLS.RECT: {
        const topLeft = nearPoint(x, y, x1, y1, POSITIONS.TOP_LEFT, offset);
        const topRight = nearPoint(x, y, x2, y1, POSITIONS.TOP_RIGHT, offset);
        const bottomLeft = nearPoint(x, y, x1, y2, POSITIONS.BOTTOM_LEFT, offset);
        const bottomRight = nearPoint(x, y, x2, y2, POSITIONS.BOTTOM_RIGHT, offset);
        const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? POSITIONS.INSIDE : null;
        return topLeft || inside || topRight || bottomLeft || bottomRight;
      }
      case TOOLS.LINE: {
        const { x: offX, y: offY } = offset;
        const a = { x: x1 + offX, y: y1 + offY };
        const b = { x: x2 + offX, y: y2 + offY };
        const c = { x: x + offX, y: y + offY };
        const tOffset = distance(a, b) - (distance(a, c) + distance(b, c));
        const inside = Math.abs(tOffset) < 1 ? POSITIONS.INSIDE : null;
        const start = nearPoint(x, y, x1, y1, POSITIONS.START, offset);
        const end = nearPoint(x, y, x2, y2, POSITIONS.END, offset);
        return inside || start || end;
      }
    }
  }

  return null;
};

const distance = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

const getElementAtPosition = (x, y, elements, offset) => {
  return elements
    .map((element) => ({
      ...element,
      position: positionWithinElement(x, y, element, offset),
    }))
    .find((element) => element?.position);
};

const getImagetAtPosition = (mouseX, mouseY, image, offset, zoom) => {
  return image
    ? [image]
        .map((img) => ({
          ...img,
          position: positionWithinElement(
            mouseX,
            mouseY,
            {
              x1: img.x,
              y1: img.y,
              x2: img.x + img.width,
              y2: img.y + img.height,
              type: TOOLS.RECT,
            },
            offset
          ),
        }))
        .find((element) => element?.position)
    : null;
};

const adjustElementCoordinates = (element) => {
  const { x1, y1, x2, y2, type } = element;
  if (type === TOOLS.LINE) {
    if (x1 < x2 || (x1 === x2 && y1 < y2)) {
      return { x1, y1, x2, y2 };
    } else {
      return { x1: x2, y1: y2, x2: x1, y2: y1 };
    }
  } else if (type === TOOLS.RECT) {
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);
    return { x1: minX, y1: minY, x2: maxX, y2: maxY };
  }
};

const cursorForPosition = (position) => {
  switch (position) {
    case POSITIONS.TOP_LEFT:
    case POSITIONS.BOTTOM_RIGHT:
    case POSITIONS.START:
    case POSITIONS.END:
      return "nwse-resize";
    case POSITIONS.TOP_RIGHT:
    case POSITIONS.BOTTOM_LEFT:
      return "nesw-resize";
    default:
      return "grab";
  }
};

const resizedCoordinates = (clientX, clientY, position, coordinates) => {
  const { x1, y1, x2, y2 } = coordinates;
  switch (position) {
    case POSITIONS.TOP_LEFT:
    case POSITIONS.START:
      return {
        x1: clientX,
        y1: clientY,
        x2: x2,
        y2: y2,
      };
    case POSITIONS.TOP_RIGHT:
      return {
        x1: x1,
        y1: clientY,
        x2: clientX,
        y2: y2,
      };
    case POSITIONS.BOTTOM_LEFT:
      return {
        x1: clientX,
        y1: y1,
        x2: x2,
        y2: clientY,
      };
    case POSITIONS.BOTTOM_RIGHT:
    case POSITIONS.END:
      return {
        x1: x1,
        y1: y1,
        x2: clientX,
        y2: clientY,
      };
    default:
      return null;
  }
};

function Mapper({ roomId, playerId }) {
  const [elements, setElements] = useState([]);
  const [action, setAction] = useState(ACTION_TYPES.NONE);
  const [tool, setTool] = useState(TOOLS.LINE);
  const [selectedElement, setSelectedElement] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [myPlayer, setMyPlayer] = useState({
    id: playerId,
    name: "",
    color: "white",
    dm: false,
  });
  const [players, setPlayers] = useState([]);
  const [playerElements, setPlayerElements] = useState([]);
  const [playerTokens, setPlayerTokens] = useState([]);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [hashes, setHashes] = useState([]);
  const [npcs, setNpcs] = useState([]);

  // Initializations
  useEffect(() => {
    // set up quick keys for tool selection
    document.addEventListener("keyup", (e) => {
      if (e.target && e.target.tagName === "BODY") {
        switch (e.key.toLowerCase()) {
          case "s":
            updateSelectedTool(TOOLS.SELECT);
            break;
          case "l":
            updateSelectedTool(TOOLS.LINE);
            break;
          case "r":
            updateSelectedTool(TOOLS.RECT);
            break;
          case "p":
            updateSelectedTool(TOOLS.PAN);
            break;
          case "d":
            updateSelectedTool(TOOLS.DELETE);
            break;
          case "t":
            updateSelectedTool(TOOLS.TOKEN);
            break;
          default:
            break;
        }
      }
    });

    // get room data
    fetch(`/api/rooms/GetRoomInfo/${roomId}/${playerId}`)
      .then((res) => res.json())
      .then((response) => {
        const hashArr = [];
        if (response.elements) {
          response.elements.forEach(({ playerId, hash }) => {
            hashArr.push(`ELEMENTS::${playerId}::${hash}`);
          });
          // in case there are new players, we don't know to look for their elements based on existing hashes
          response.room.players?.forEach((player) => {
            let foundIndex = hashArr.findIndex((hash) => hash.includes(`ELEMENTS::${player.id}`));
            if (foundIndex < 0) {
              hashArr.push(`ELEMENTS::${player.id}::REQUEST`);
            }
          });
        }
        if (response.tokens) {
          response.tokens.forEach(({ playerId, hash }) => {
            hashArr.push(`TOKEN::${playerId}::${hash}`);
          });
          // in case there are new players, we don't know to look for their tokens based on existing hashes
          response.room.players?.forEach((player) => {
            let foundIndex = hashArr.findIndex((hash) => hash.includes(`TOKEN::${player.id}`));
            if (foundIndex < 0) {
              hashArr.push(`TOKEN::${player.id}::REQUEST`);
            }
          });
        }
        if (!myPlayer.dm) {
          if (response.npcs && response.npcs.hash) {
            const hash = response.npcs.hash;
            hashArr.push(`NPC::${hash}`);
          } else {
            hashArr.push("NPC::REQUEST");
          }
        }
        hashArr.push(`ROOM::${response.room.hash}`);
        setHashes(hashArr);
        setMyPlayer(response.room.players.find((p) => p.id === playerId));
        setPlayers(response.room.players);
        setPlayerElements(response.elements ?? []);
        setPlayerTokens(response.tokens ?? []);
        setNpcs(response.npcs?.npcs ?? []);
        if (response.room.backgroundImage) {
          if (response.room.backgroundImage.src) {
            setBackgroundImageUrl(response.room.backgroundImage.src);
          }
          setBackgroundImageInfo(response.room.backgroundImage);
        }
      });
  }, []);

  useEffect(() => {
    checkForUpdatesContinuous(hashes);
  }, [hashes]);

  useEffect(() => {
    updateNPCsOnServer(npcs);
  }, [npcs]);

  const checkForUpdatesContinuous = (hashesArr) => {
    window.clearTimeout(window.checkForUpdatesInterval);
    window.checkForUpdatesInterval = window.setTimeout(() => {
      // send up all hashes, recieve updated everything if the hashes don't match
      // after response, call this function again
      try {
        fetch(`/api/rooms/GetRoomUpdates/${roomId}/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ hashes: hashesArr && hashesArr.length ? hashesArr : hashes }),
        })
          .then((res) => res.json())
          .then((response) => {
            const hashClone = [...hashes];
            let updateHashes = false;
            if (response.room !== "NO UPDATE") {
              updateHashes = true;
              setPlayers(response.room.players);
              if (response.room.backgroundImage) {
                if (response.room.backgroundImage.src) {
                  setBackgroundImageUrl(response.room.backgroundImage.src);
                }
                setBackgroundImageInfo(response.room.backgroundImage);
              }
              hashClone[hashClone.findIndex((hash) => hash.includes("ROOM"))] = `ROOM::${response.room.hash}`;
            }
            if (response.elements !== "NO UPDATE") {
              updateHashes = true;
              const playerElsCopy = [...playerElements];
              response.elements.forEach(({ playerId, hash, elements }) => {
                const playerIndex = hashClone.findIndex((hash) => hash.includes(`ELEMENTS::${playerId}`));
                if (playerIndex >= 0) {
                  hashClone[playerIndex] = `ELEMENTS::${playerId}::${hash}`;
                }
                const playerElsIndex = playerElsCopy.findIndex((pe) => pe.playerId === playerId);
                if (playerElsIndex >= 0) {
                  playerElsCopy[playerElsIndex] = { playerId, elements };
                } else {
                  playerElsCopy.push({ playerId, elements: response.elements.filter((el) => el.playerId === playerId) });
                }
              });

              setPlayerElements(playerElsCopy);
            }
            if (response.tokens !== "NO UPDATE") {
              updateHashes = true;
              response.tokens.forEach(({ playerId, hash, x, y }) => {
                const playerIndex = hashClone.findIndex((hash) => hash.includes(`TOKEN::${playerId}`));
                if (playerIndex >= 0) {
                  hashClone[playerIndex] = `TOKEN::${playerId}::${hash}`;
                }
                const tokenIndex = playerTokens.findIndex((token) => token.playerId === playerId);
                if (tokenIndex >= 0) {
                  const tokensClone = [...playerTokens];
                  tokensClone[tokenIndex] = { playerId, x, y };
                  setPlayerTokens(tokensClone);
                } else {
                  setPlayerTokens((prevTokens) => prevTokens.concat({ playerId, x, y }));
                }
              });
            }
            if (response.npcs !== "NO UPDATE" && !myPlayer.dm) {
              updateHashes = true;
              setNpcs(response.npcs.npcs);
              hashClone[hashClone.findIndex((hash) => hash.includes("NPC"))] = `NPC::${response.npcs.hash}`;
            }
            if (updateHashes) {
              setHashes(hashClone);
            } else {
              checkForUpdatesContinuous(hashesArr);
            }
          })
          .catch(() => {
            console.error("Could not get updates");
            checkForUpdatesContinuous(hashesArr);
          });
      } catch (error) {
        console.error("Could not get updates", error);
        checkForUpdatesContinuous(hashesArr);
      }
    }, 2500);
  };

  // set up elements when players or elements are updated
  useEffect(() => {
    let els = [];
    players.forEach((player) => {
      const pEls = playerElements.find((pe) => pe.playerId === player.id)?.elements;
      if (pEls) {
        pEls.forEach(({ x1, y1, x2, y2, id, type }) => {
          els.push(createElement(player.id, id, x1 * zoom, y1 * zoom, x2 * zoom, y2 * zoom, type, panPosition, { x1, y1, x2, y2 }, player.color));
        });
      }
    });
    els = els.sort((a, b) => a.id - b.id);
    setElements(els);
  }, [players, playerElements, myPlayer]);

  useLayoutEffect(() => {
    const canvas = document.getElementById("myCanvas");
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const roughCanvas = rough.canvas(canvas);

    // draw image onto canvas
    const { x: offX, y: offY } = panPosition;
    if (backgroundImage) {
      const { image, width, height, x, y } = backgroundImage;
      context.drawImage(image, Math.round(x + offX), Math.round(y + offY), Math.round(width), Math.round(height));
    }

    if (elements.length) {
      elements.forEach(({ element }) => {
        roughCanvas.draw(element);
      });
    }

    if (npcs && npcs.length) {
      npcs.forEach((npc) => {
        if (npc.x && npc.y) {
          roughCanvas.circle(npc.x * zoom + panPosition.x, npc.y * zoom + panPosition.y, 20 * zoom, {
            fill: npc.visible ? "red" : "black",
            fillStyle: "solid",
          });
          context.font = `${18 * zoom}px Arial`;
          context.fillStyle = "white";
          context.fillText(npc.name && npc.name.length ? npc.name.substr(0, 1) : "?", (npc.x - 6) * zoom + panPosition.x, (npc.y + 6) * zoom + panPosition.y);
        }
      });
    }

    if (playerTokens && playerTokens.length) {
      playerTokens.forEach(({ playerId, x, y }) => {
        const player = players.find((p) => p.id === playerId);
        if (player && !player.dm) {
          if (player) {
            roughCanvas.circle(x * zoom + panPosition.x, y * zoom + panPosition.y, 20 * zoom, {
              fill: player.color,
              fillStyle: "solid",
            });
          }
        }
      });
    }
  }, [elements, backgroundImage, playerTokens, myPlayer, players, npcs]);

  const updateElement = (playerId, id, x1, y1, x2, y2, type, offset, originalPosAndSize, color) => {
    const updatedElement = createElement(playerId, id, x1, y1, x2, y2, type, offset, originalPosAndSize, color);

    const elementsCopy = [...elements];
    const elIndex = elementsCopy.findIndex(({ id: eId }) => eId === id);
    elementsCopy[elIndex] = updatedElement;
    setElements(elementsCopy);
  };

  const clientToWorld = (e) => {
    return {
      clientX: e.clientX - panPosition.x,
      clientY: e.clientY - panPosition.y,
    };
  };

  const handleMouseDown = (e) => {
    const { clientX, clientY } = clientToWorld(e);
    const myElements = elements.filter(({ playerId }) => playerId === myPlayer.id);

    if (tool === TOOLS.SELECT) {
      const element = getElementAtPosition(clientX, clientY, myElements, panPosition);
      if (element) {
        const offsetX = clientX - element.x1;
        const offsetY = clientY - element.y1;
        setSelectedElement({ ...element, offsetX, offsetY });

        if (element.position === POSITIONS.INSIDE) {
          setAction(ACTION_TYPES.MOVING);
        } else {
          setAction(ACTION_TYPES.RESIZING);
        }
      }
    } else if (tool === TOOLS.IMAGE) {
      if (backgroundImage && imageLoaded) {
        const image = getImagetAtPosition(clientX, clientY, backgroundImage, panPosition, zoom);
        if (image) {
          const offsetX = clientX - image.x;
          const offsetY = clientY - image.y;
          setSelectedElement({
            ...image,
            offsetX: offsetX,
            offsetY: offsetY,
            position: image.position,
          });

          if (image.position !== POSITIONS.BOTTOM_RIGHT) {
            setAction(ACTION_TYPES.MOVING);
          } else {
            setAction(ACTION_TYPES.RESIZING);
          }
        }
      }
    } else if (tool === TOOLS.DELETE) {
      const element = getElementAtPosition(clientX, clientY, myElements, panPosition);
      if (element) {
        const elementsCopy = elements.filter(({ id }) => id !== element.id);
        elementsCopy.forEach((element, index) => {
          element.id = index;
        });
        setElements(elementsCopy);
      }
    } else if (tool === TOOLS.PAN) {
      setAction(ACTION_TYPES.PANNING);
      setSelectedElement({
        clientX: e.clientX,
        clientY: e.clientY,
        ...panPosition,
        elementsCopy: [...elements],
      });
    } else if (tool === TOOLS.TOKEN) {
      const tokenIndex = playerTokens.findIndex(({ playerId }) => playerId === myPlayer.id);
      if (tokenIndex >= 0) {
        const tokensClone = [...playerTokens];
        tokensClone[tokenIndex] = { ...tokensClone[tokenIndex], x: clientX / zoom, y: clientY / zoom };
        setPlayerTokens(tokensClone);
      } else {
        setPlayerTokens((prevTokens) => prevTokens.concat({ playerId: myPlayer.id, x: clientX / zoom, y: clientY / zoom }));
      }
    } else if (tool.startsWith(TOOLS.PLACE_NPC_TOKEN)) {
      const npcIndex = npcs.findIndex((npc) => npc.id == tool.split("::")[1]);
      console.log(npcs, tool.split("::")[1], npcIndex);
      if (npcIndex >= 0) {
        const npcsClone = [...npcs];
        npcsClone[npcIndex] = { ...npcsClone[npcIndex], x: clientX / zoom, y: clientY / zoom };
        setNpcs(npcsClone);
      }
    } else {
      setAction(ACTION_TYPES.DRAWING);
      const element = createElement(myPlayer.id, new Date().valueOf(), clientX, clientY, clientX, clientY, tool, panPosition, null, myPlayer.color);
      setSelectedElement(element);
      setElements((prevElements) => prevElements.concat(element));
    }
  };

  const handleMouseMove = (e) => {
    const { clientX, clientY } = clientToWorld(e);

    const myElements = elements.filter(({ playerId }) => playerId === myPlayer.id);
    if (tool === TOOLS.SELECT && action === ACTION_TYPES.NONE) {
      const element = getElementAtPosition(clientX, clientY, myElements, panPosition);
      e.target.style.cursor = element ? cursorForPosition(element.position) : "default";
    } else if (tool === TOOLS.IMAGE && action === ACTION_TYPES.NONE) {
      if (backgroundImage && imageLoaded) {
        const image = getImagetAtPosition(clientX, clientY, backgroundImage, panPosition, zoom);
        if (image) {
          if (image.position !== POSITIONS.BOTTOM_RIGHT) {
            e.target.style.cursor = "grab";
          } else {
            e.target.style.cursor = "nwse-resize";
          }
        } else {
          e.target.style.cursor = "default";
        }
      }
    } else if (tool === TOOLS.DELETE) {
      e.target.style.cursor = getElementAtPosition(clientX, clientY, myElements, panPosition) ? "not-allowed" : "default";
    } else if ((tool === TOOLS.SELECT || tool === TOOLS.IMAGE) && action === ACTION_TYPES.MOVING) {
      e.target.style.cursor = "grabbing";
    } else if (tool === TOOLS.PAN) {
      if (action === ACTION_TYPES.PANNING) {
        e.target.style.cursor = "grabbing";
      } else {
        e.target.style.cursor = "grab";
      }
    } else if (tool === TOOLS.TOKEN) {
      e.target.style.cursor = "default";
    }

    if (action === ACTION_TYPES.DRAWING) {
      const index = elements.findIndex(({ id }) => id === selectedElement.id);
      const { x1, y1, id } = elements[index];
      updateElement(
        myPlayer.id,
        id,
        x1,
        y1,
        clientX,
        clientY,
        tool,
        panPosition,
        { x1: x1 / zoom, y1: y1 / zoom, x2: clientX / zoom, y2: clientY / zoom },
        myPlayer.color
      );
    } else if (action === ACTION_TYPES.MOVING) {
      if (tool === TOOLS.IMAGE) {
        const { width, height, offsetX, offsetY } = selectedElement;
        const newX = clientX - offsetX;
        const newY = clientY - offsetY;
        setBackgroundImage({
          ...backgroundImage,
          x: newX,
          y: newY,
          width: width,
          height: height,
          originalPositionAndSize: {
            x1: newX / zoom,
            y1: newY / zoom,
            x2: width / zoom,
            y2: height / zoom,
          },
        });
      } else {
        const { id, x1, x2, y1, y2, type, offsetX, offsetY } = selectedElement;
        const width = x2 - x1;
        const height = y2 - y1;
        const newX1 = clientX - offsetX;
        const newY1 = clientY - offsetY;
        updateElement(
          myPlayer.id,
          id,
          newX1,
          newY1,
          newX1 + width,
          newY1 + height,
          type,
          panPosition,
          {
            x1: newX1 / zoom,
            y1: newY1 / zoom,
            x2: (newX1 + width) / zoom,
            y2: (newY1 + height) / zoom,
          },
          myPlayer.color
        );
      }
    } else if (action === ACTION_TYPES.RESIZING) {
      if (tool === TOOLS.IMAGE) {
        const { x, y, width, height, position } = selectedElement;
        const { x1, y1, x2, y2 } = resizedCoordinates(clientX, clientY, position, { x1: x, y1: y, x2: width, y2: height });
        const newWidth = Math.max(x2 - x1, 20);
        const newHeight = Math.max(y2 - y1, 20);
        setBackgroundImage({
          ...backgroundImage,
          x: x1,
          y: y1,
          width: newWidth,
          height: newHeight,
          originalPositionAndSize: {
            ...backgroundImage.originalPositionAndSize,
            x2: newWidth / zoom,
            y2: newHeight / zoom,
          },
        });
      } else {
        const { id, type, position, ...coordinates } = selectedElement;
        const { x1, y1, x2, y2 } = resizedCoordinates(clientX, clientY, position, coordinates);
        updateElement(
          myPlayer.id,
          id,
          x1,
          y1,
          x2,
          y2,
          type,
          panPosition,
          {
            x1: x1 / zoom,
            y1: y1 / zoom,
            x2: x2 / zoom,
            y2: y2 / zoom,
          },
          myPlayer.color
        );
      }
    } else if (action === ACTION_TYPES.PANNING) {
      const { clientX: startClientX, clientY: startClientY, x: originalPanX, y: originalPanY, elementsCopy } = selectedElement;
      const diffX = e.clientX - startClientX;
      const diffY = e.clientY - startClientY;
      const newPanX = originalPanX + diffX;
      const newPanY = originalPanY + diffY;
      const newPan = { x: newPanX, y: newPanY };
      setPanPosition(newPan);
      // update all elements

      const copy = [...elements];

      elementsCopy.forEach((element, i) => {
        copy[i] = createElement(
          element.playerId,
          element.id,
          element.x1,
          element.y1,
          element.x2,
          element.y2,
          element.type,
          newPan,
          element.originalPositionAndSize,
          players.find((p) => p.id === element.playerId).color
        );
      });
      setElements(copy);
    }
  };

  const handleMouseUp = (e) => {
    if (tool === TOOLS.LINE || tool === TOOLS.RECT) {
      if (selectedElement) {
        const { id, type } = selectedElement;
        const elIndex = elements.findIndex(({ id: eId }) => eId === id);
        const el = elements[elIndex];
        const { x1, y1, x2, y2 } = adjustElementCoordinates(el);
        if (action === ACTION_TYPES.DRAWING && distance({ x: x1 / zoom, y: y1 / zoom }, { x: x2 / zoom, y: y2 / zoom }) < 20) {
          const elementsCopy = elements.filter(({ id: eId }) => eId !== id);
          setElements(elementsCopy);
        } else if (action === ACTION_TYPES.DRAWING) {
          updateElement(
            myPlayer.id,
            id,
            x1,
            y1,
            x2,
            y2,
            type,
            panPosition,
            {
              x1: x1 / zoom,
              y1: y1 / zoom,
              x2: x2 / zoom,
              y2: y2 / zoom,
            },
            myPlayer.color
          );
          const playerElementsClone = [...playerElements];
          const playerIndex = playerElementsClone.findIndex((pe) => pe.playerId === myPlayer.id);
          if (playerIndex >= 0) {
            const playerEls = playerElementsClone[playerIndex].elements;
            const elementIndex = playerEls.findIndex((el) => el.id === id);
            if (elementIndex >= 0) {
              playerEls[elementIndex] = { id, x1, y1, x2, y2, type };
            } else {
              playerEls.push({ id, x1, y1, x2, y2, type });
            }
          } else {
            playerElementsClone.push({ playerId: myPlayer.id, elements: [{ id, x1, y1, x2, y2, type }] });
          }
          setPlayerElements(playerElementsClone);
        }
        updateElementsOnServer(myPlayer.id);
      }
    }
    if (tool === TOOLS.TOKEN) {
      const tokenIndex = playerTokens.findIndex(({ playerId }) => playerId === myPlayer.id);
      fetch(`/api/rooms/UpdatePlayerToken/${roomId}/${myPlayer.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tokenPosition: { x: playerTokens[tokenIndex].x, y: playerTokens[tokenIndex].y } }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.Success) {
            const hashClone = [...hashes];
            const tokenIndex = hashClone.findIndex((hash) => hash.includes(`TOKEN::${myPlayer.id}`));
            if (tokenIndex >= 0) {
              hashClone[tokenIndex] = `TOKEN::${myPlayer.id}::${response.Id}`;
            } else {
              hashClone.push(`TOKEN::${myPlayer.id}::${response.Id}`);
            }
            setHashes(hashClone);
          }
        });
    }
    if (tool === TOOLS.IMAGE) {
      updateBackgroundImageOnServer({
        src: backgroundImage.src,
        x: backgroundImage.originalPositionAndSize.x1,
        y: backgroundImage.originalPositionAndSize.y1,
        width: backgroundImage.originalPositionAndSize.x2,
        height: backgroundImage.originalPositionAndSize.y2,
      });
    }
    if ((tool === TOOLS.SELECT && (action === ACTION_TYPES.MOVING || action === ACTION_TYPES.RESIZING)) || tool === TOOLS.DELETE) {
      updateElementsOnServer(myPlayer.id);
    }
    setAction(ACTION_TYPES.NONE);
    e.target.style.cursor = "default";
    setSelectedElement(null);
  };

  const updateNPCsOnServer = (npcArr) => {
    // debounce the request by 1 second
    window.clearTimeout(window[`npcsUpdateTimeout`]);
    window[`npcsUpdateTimeout`] = setTimeout(() => {
      const postObj = {};
      npcArr.forEach((npc) => {
        postObj[`npc::${npc.id}`] = `id:${npc.id}|:|x:${npc.x || 0}|:|y:${npc.y || 0}|:|visible:${npc.visible}|:|name:${npc.name || ""}`;
      });
      fetch(`/api/rooms/UpdateNPCs/${roomId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postObj),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.Success) {
            const hashClone = [...hashes];
            const npcIndex = hashClone.findIndex((hash) => hash.includes(`NPC::`));
            // should always exist
            if (npcIndex >= 0) {
              hashClone[npcIndex] = `NPC::${response.Id}`;
            } else {
              hashClone.push(`NPC::${response.Id}`);
            }
            setHashes(hashClone);
          }
        });
    }, 1000);
  };

  const handleScrollWheel = (e) => {
    const { wheelDelta } = e.nativeEvent;
    const maxZoom = 5;
    const minZoom = 0.6;
    let newZoom = zoom;
    if (wheelDelta > 0) {
      // zoom in
      newZoom = zoom + 0.2;
    } else {
      // zoom out
      newZoom = zoom - 0.2;
    }
    newZoom = Math.round(Math.min(Math.max(newZoom, minZoom), maxZoom) * 10) / 10;
    if (newZoom !== zoom) {
      setZoom(newZoom);
      const { x: offX, y: offY } = panPosition;
      const viewportCenterX = window.innerWidth / 2;
      const viewportCenterY = window.innerHeight / 2;
      const deltaX = viewportCenterX - offX;
      const deltaY = viewportCenterY - offY;
      const newPanX = offX - (deltaX * (newZoom - zoom)) / zoom;
      const newPanY = offY - (deltaY * (newZoom - zoom)) / zoom;
      const newPan = { x: newPanX, y: newPanY };
      setPanPosition(newPan);

      if (backgroundImage && imageLoaded) {
        const newImage = {
          ...backgroundImage,
          x: backgroundImage.originalPositionAndSize.x1 * newZoom,
          y: backgroundImage.originalPositionAndSize.y1 * newZoom,
          width: backgroundImage.originalPositionAndSize.x2 * newZoom,
          height: backgroundImage.originalPositionAndSize.y2 * newZoom,
        };
        setBackgroundImage(newImage);
      }

      const copy = [...elements];
      elements.forEach((element, i) => {
        copy[i] = createElement(
          element.playerId,
          element.id,
          element.originalPositionAndSize.x1 * newZoom,
          element.originalPositionAndSize.y1 * newZoom,
          element.originalPositionAndSize.x2 * newZoom,
          element.originalPositionAndSize.y2 * newZoom,
          element.type,
          newPan,
          element.originalPositionAndSize,
          players.find((p) => p.id === element.playerId).color
        );
      });
      setElements(copy);
    }
  };

  const setBackgroundImageInfo = (imageInfo, updateServer = false) => {
    setImageLoaded(false);
    setBackgroundImage(null);
    const image = new Image();
    image.src = imageInfo.src;
    image.onload = () => {
      const imgData = {
        src: imageInfo.src,
        width: (imageInfo.width || image.width) * zoom,
        height: (imageInfo.height || image.height) * zoom,
        x: (imageInfo.x || 0) * zoom,
        y: (imageInfo.y || 0) * zoom,
        originalPositionAndSize: {
          x1: imageInfo.x || 0,
          y1: imageInfo.y || 0,
          x2: imageInfo.width || image.width,
          y2: imageInfo.height || image.height,
        },
      };
      setBackgroundImage({
        ...imgData,
        image,
      });
      if (updateServer) {
        // update server
        updateBackgroundImageOnServer({
          x: imgData.originalPositionAndSize.x1,
          y: imgData.originalPositionAndSize.y1,
          width: imgData.originalPositionAndSize.x2,
          height: imgData.originalPositionAndSize.y2,
          src: imageInfo.src,
        });
      }
      setTimeout(() => {
        setImageLoaded(true);
      }, 400);
    };
  };

  const updateElementsOnServer = (playerIdForUpdate) => {
    // debounce the request by 1 second
    window.clearTimeout(window[`playerElementsUpdateTimeout${playerIdForUpdate}`]);
    window[`playerElementsUpdateTimeout${playerIdForUpdate}`] = setTimeout(() => {
      const els = elements
        .filter(({ playerId }) => playerId === playerIdForUpdate)
        .map(({ id, originalPositionAndSize: og, type }) => ({
          type,
          id,
          x1: og.x1,
          y1: og.y1,
          x2: og.x2,
          y2: og.y2,
        }));
      fetch(`/api/rooms/UpdatePlayerElements/${roomId}/${playerIdForUpdate}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ elements: els }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.Success) {
            const hashClone = [...hashes];
            const playerIndex = hashClone.findIndex((hash) => hash.includes(`ELEMENTS::${playerIdForUpdate}`));
            if (playerIndex >= 0) {
              hashClone[playerIndex] = `ELEMENTS::${playerIdForUpdate}::${response.Id}`;
            } else {
              hashClone.push(`ELEMENTS::${playerIdForUpdate}::${response.Id}`);
            }
            setHashes(hashClone);
          }
        });
    }, 1000);
  };

  // debounced update to server
  const updateBackgroundImageOnServer = (imageInfo) => {
    // debounce the request by 1 second
    window.clearTimeout(window.updateBackgroundImageTimeout);
    window.updateBackgroundImageTimeout = setTimeout(() => {
      fetch(`/api/rooms/UpdateBackgroundImage/${roomId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ backgroundImage: imageInfo }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.Success) {
            const hashClone = [...hashes];
            const roomIndex = hashClone.findIndex((hash) => hash.includes(`ROOM::`));
            if (roomIndex >= 0) {
              // should always be true
              hashClone[roomIndex] = `ROOM::${response.Id}`;
            } else {
              hashClone.push(`ROOM::${response.Id}`);
            }
            setHashes(hashClone);
          }
        });
    }, 1000);
  };

  // https://fastly.picsum.photos/id/288/200/300.jpg?hmac=45WLionXnoogi0-njKuSNnVY5hnswMhf-CrxwzKTcrc
  const handleImageChange = (newValue) => {
    if (!backgroundImage || newValue !== backgroundImage.src) {
      const backgroundImage = {
        src: newValue,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        originalPositionAndSize: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        },
      };
      setBackgroundImageInfo(backgroundImage, true);
    }
  };

  const updateSelectedTool = (tool) => {
    setTool(tool);
    const canvas = document.getElementById("myCanvas");
    canvas.style.cursor = "default";
  };

  const handlePlayerNameChanged = (e) => {
    const playerClone = { ...myPlayer };
    playerClone.name = e.target.value;
    setMyPlayer(playerClone);
    const playersClone = [...players];
    const playerIndex = playersClone.findIndex((p) => p.id === myPlayer.id);
    playersClone[playerIndex].name = e.target.value;
    setPlayers(playersClone);
    updatePlayer({ ...myPlayer, name: e.target.value });
  };

  const handlePlayerInitiativeChanged = (e) => {
    const playerClone = { ...myPlayer };
    const newInitiative = Number(e.target.value);
    playerClone.initiative = newInitiative;
    setMyPlayer(playerClone);
    const playersClone = [...players];
    const playerIndex = playersClone.findIndex((p) => p.id === myPlayer.id);
    playersClone[playerIndex].initiative = newInitiative;
    setPlayers(playersClone);
    updatePlayer({ ...myPlayer, initiative: newInitiative });
  };

  const updatePlayerColor = (playerId, color) => {
    // should be always - for now
    if (playerId === myPlayer.id) {
      const myPlayerClone = { ...myPlayer };
      myPlayerClone.color = color;
      setMyPlayer(myPlayerClone);
    }
    const playersClone = [...players];
    const playerIndex = playersClone.findIndex((p) => p.id === playerId);
    playersClone[playerIndex].color = color;
    setPlayers(playersClone);
    updatePlayer({ ...myPlayer, color });
  };

  const updatePlayer = ({ id: playerId, name, color, initiative }) => {
    // debounce the request by 1 second
    window.clearTimeout(window[`playerUpdateTimeout${playerId}`]);
    window[`playerUpdateTimeout${playerId}`] = setTimeout(() => {
      fetch(`/api/rooms/UpdatePlayer/${roomId}/${playerId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ player: { color, name, initiative: initiative || 0 } }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.Success) {
            const hashClone = [...hashes];
            const roomIndex = hashClone.findIndex((hash) => hash.includes(`ROOM::`));
            if (roomIndex >= 0) {
              hashClone[roomIndex] = `ROOM::${response.Id}`;
            }
            setHashes(hashClone);
          }
        });
    }, 1000);
  };

  const handleNPCNameChanged = (e, npcId) => {
    const npcIndex = npcs.findIndex((npc) => npc.id === npcId);
    if (npcIndex >= 0) {
      const npcsClone = [...npcs];
      npcsClone[npcIndex] = { ...npcsClone[npcIndex], name: e.target.value };
      setNpcs(npcsClone);
    }
  };

  const handleNPCVisibilityChanged = (e, npcId) => {
    const npcIndex = npcs.findIndex((npc) => npc.id === npcId);
    if (npcIndex >= 0) {
      const npcsClone = [...npcs];
      npcsClone[npcIndex] = { ...npcsClone[npcIndex], visible: e.target.checked };
      setNpcs(npcsClone);
    }
  };

  const handleNPCInitiativeChanged = (e, npcId) => {
    const npcIndex = npcs.findIndex((npc) => npc.id === npcId);
    if (npcIndex >= 0) {
      const npcsClone = [...npcs];
      npcsClone[npcIndex] = { ...npcsClone[npcIndex], initiative: Number(e.target.value) };
      setNpcs(npcsClone);
    }
  };

  const handleLeaveRoom = () => {
    const result = window.confirm("Are you sure you want to leave the room? All your changes will be lost.");
    if (result) {
      fetch(`/api/rooms/LeaveRoom/${roomId}/${myPlayer.id}`, {
        method: "PUT",
      })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          localStorage.removeItem("playerId");
          localStorage.removeItem("roomId");
          window.location.href = "/";
        });
    }
  };

  return (
    <div onWheel={handleScrollWheel}>
      <div
        style={{
          position: "fixed",
          zIndex: 15,
          background: "#99999999",
          padding: "10px",
        }}
      >
        {myPlayer.dm && (
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              id="imageURL"
              placeholder="Image URL here"
              onBlur={(e) => {
                handleImageChange(e.target.value);
              }}
              onChange={(e) => setBackgroundImageUrl(e.target.value)}
              value={backgroundImageUrl}
            />
            &nbsp;<label htmlFor="imageURL">Image URL</label>&nbsp;
            <input type="radio" name="tool" id={TOOLS.IMAGE} checked={tool === TOOLS.IMAGE} onChange={() => updateSelectedTool(TOOLS.IMAGE)} />
            &nbsp;<label htmlFor={TOOLS.IMAGE}>Image Manipulation</label>
          </div>
        )}
        <div style={{ marginBottom: "10px" }}>
          <input
            type="radio"
            name="tool"
            id={TOOLS.SELECT}
            value={TOOLS.SELECT}
            checked={tool === TOOLS.SELECT}
            onChange={() => updateSelectedTool(TOOLS.SELECT)}
          />
          <label htmlFor={TOOLS.SELECT}>Select (S)</label>
          &nbsp;
          <input
            type="radio"
            name="tool"
            id={TOOLS.DELETE}
            value={TOOLS.DELETE}
            checked={tool === TOOLS.DELETE}
            onChange={() => updateSelectedTool(TOOLS.DELETE)}
          />
          <label htmlFor={TOOLS.DELETE}>Delete (D)</label>
          &nbsp;
          <input type="radio" name="tool" id={TOOLS.LINE} value={TOOLS.LINE} checked={tool === TOOLS.LINE} onChange={() => updateSelectedTool(TOOLS.LINE)} />
          <label htmlFor={TOOLS.LINE}>Line (L)</label>
          &nbsp;
          <input type="radio" name="tool" id={TOOLS.RECT} value={TOOLS.RECT} checked={tool === TOOLS.RECT} onChange={() => updateSelectedTool(TOOLS.RECT)} />
          <label htmlFor={TOOLS.RECT}>Rectangle (R)</label>
          &nbsp;
          <input type="radio" name="tool" id={TOOLS.PAN} value={TOOLS.PAN} checked={tool === TOOLS.PAN} onChange={() => updateSelectedTool(TOOLS.PAN)} />
          <label htmlFor={TOOLS.PAN}>Pan (P)</label>
        </div>
        <div>
          <input type="text" id="playerName" placeholder="Player Name Here" onChange={handlePlayerNameChanged} value={myPlayer.name} />
          &nbsp;<label htmlFor="playerName">Player Name</label>&nbsp;
          {!myPlayer.dm && (
            <>
              <input
                type="radio"
                name="tool"
                id={TOOLS.TOKEN}
                value={TOOLS.TOKEN}
                checked={tool === TOOLS.TOKEN}
                onChange={() => updateSelectedTool(TOOLS.TOKEN)}
              />
              &nbsp;
              <label htmlFor={TOOLS.TOKEN}>Token (T)</label>&nbsp;
            </>
          )}
          <select name="color" id="playerColor" value={myPlayer.color} onChange={(e) => updatePlayerColor(myPlayer.id, e.target.value)}>
            <option value="white">White</option>
            <option value="red">Red</option>
            <option value="blue">Blue</option>
            <option value="green">Green</option>
            <option value="yellow">Yellow</option>
            <option value="cyan">Cyan</option>
            <option value="magenta">Magenta</option>
            <option value="orange">Orange</option>
            <option value="purple">Purple</option>
            <option value="brown">Brown</option>
            <option value="gray">Gray</option>
          </select>
          &nbsp;
          <label htmlFor="playerColor">Color</label>
        </div>
        <div>
          <button onClick={() => handleLeaveRoom()}>Leave Room</button>
        </div>
      </div>
      <div>
        <canvas
          id="myCanvas"
          style={{ position: "fixed", zIndex: 10, backgroundColor: "#333" }}
          width={"3000px"}
          height={"2000px"}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        ></canvas>
      </div>
      <div
        id="fogGrid"
        style={{
          backgroundColor: "#ffffff",
          opacity: 0.05,
          background:
            "repeating-linear-gradient(90deg, #FFF 0, #FFF 5%, transparent 0, transparent 50%), repeating-linear-gradient(180deg, #FFF 0, #FFF 5%, transparent 0, transparent 50%)",
          backgroundPosition: `${panPosition.x}px ${panPosition.y}px`,
          backgroundSize: `${3 * zoom}em ${3 * zoom}em`,
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 50,
          pointerEvents: "none",
          top: 0,
          left: 0,
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          zIndex: 60,
          top: "10px",
          right: "10px",
          background: "#00000099",
          padding: "20px",
          maxHeight: "calc(100vh - 20px)",
          overflowY: "auto",
          width: "400px",
          boxSizing: "border-box",
        }}
      >
        <input type="readonly" id="roomID" value={roomId} />
        &nbsp;
        <label htmlFor="roomID" style={{ color: "white" }}>
          Room ID
        </label>
        <h2 style={{ color: "white", textAlign: "center", textDecoration: "underline" }}>Players</h2>
        {players
          .filter((p) => !p.dm)
          .map((p) => (
            <div key={p.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <h2 style={{ color: p.color }}>{p.name || "[Unset]"}</h2>
              <input
                type={p.id === myPlayer.id ? "number" : "readonly"}
                value={p.initiative || 0}
                min={0}
                step={1}
                style={{ width: "50px", height: "30px", alignSelf: "center" }}
                onChange={(e) => p.id === myPlayer.id && handlePlayerInitiativeChanged(e)}
              />
            </div>
          ))}
        <h2 style={{ color: "white", textAlign: "center", textDecoration: "underline" }}>NPCs</h2>
        {myPlayer.dm ? (
          <div>
            {npcs.map((npc) => (
              <div key={npc.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <input
                  type="radio"
                  name="tool"
                  value={`${TOOLS.PLACE_NPC_TOKEN}::${npc.id}`}
                  checked={tool === `${TOOLS.PLACE_NPC_TOKEN}::${npc.id}`}
                  onChange={() => updateSelectedTool(`${TOOLS.PLACE_NPC_TOKEN}::${npc.id}`)}
                />
                &nbsp;
                <input type="text" value={npc.name} placeholder="Set NPC Name" onChange={(e) => handleNPCNameChanged(e, npc.id)} />
                &nbsp;
                <input type="checkbox" checked={npc.visible} id={`npc${npc.id}visible`} onChange={(e) => handleNPCVisibilityChanged(e, npc.id)} />
                &nbsp;
                <label htmlFor={`npc${npc.id}visible`} style={{ color: "white" }}>
                  Visible
                </label>
                &nbsp;
                <input
                  type="number"
                  value={npc.initiative || 0}
                  min={0}
                  step={1}
                  style={{ width: "50px", height: "30px" }}
                  onChange={(e) => handleNPCInitiativeChanged(e, npc.id)}
                />
                &nbsp;
                <button onClick={() => setNpcs((x) => x.filter((n) => n.id !== npc.id))}>X</button>
              </div>
            ))}
            <button style={{ width: "100%" }} onClick={() => setNpcs((x) => [...x, { id: new Date().valueOf(), visible: false }])}>
              Add NPC
            </button>
          </div>
        ) : (
          <div>
            {npcs.map((npc) => (
              <div key={npc.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h4 style={{ color: "red", margin: "10px 0" }}>{npc.name || "[Unset]"}</h4>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Mapper;
