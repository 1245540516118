import { useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import Mapper from "./Mapper";

function App() {
  const [roomId, setRoomId] = useLocalStorage("roomId", "");
  const [playerId, setPlayerId] = useLocalStorage("playerId", "");
  const [joiningRoom, setJoiningRoom] = useState(false);

  const joinRoom = () => {
    const rId = document.getElementById("roomIdInput").value;
    if (!rId) {
      alert("Please enter a room ID");
      return;
    }
    setJoiningRoom(true);
    fetch("/api/rooms/joinRoom", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ roomId: rId, playerId }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (playerId !== response.playerId) {
          setPlayerId(response.playerId);
        }
        setRoomId(response.roomId);
        setJoiningRoom(false);
      })
      .catch(() => {
        alert("Could not join room");
        setJoiningRoom(false);
      });
  };

  const createRoom = () => {
    setJoiningRoom(true);
    fetch("/api/rooms/createRoom", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ playerId }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (playerId !== response.playerId) {
          setPlayerId(response.playerId);
        }
        setRoomId(response.roomId);
        setJoiningRoom(false);
      })
      .catch(() => {
        alert("Could not create room");
        setJoiningRoom(false);
      });
  };

  return roomId ? (
    <Mapper roomId={roomId} playerId={playerId}></Mapper>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        background: "#333",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Tabletop&nbsp;Mapper</h1>
        <input type="text" id="roomIdInput" placeholder="Enter Room ID" disabled={joiningRoom}></input>
        {/* Join room */}
        <button onClick={() => joinRoom()} style={{ marginTop: "10px", padding: "10px" }} disabled={joiningRoom}>
          Join Room
        </button>
        <hr style={{ width: "100%" }} />
        {/* Create room */}
        <button onClick={() => createRoom()} style={{ padding: "10px" }} disabled={joiningRoom}>
          Create Room
        </button>
      </div>
    </div>
  );
}

export default App;
